import { DecodedIdToken } from 'firebase-admin/lib/auth/token-verifier';
import { User, signInWithCustomToken, signOut } from 'firebase/auth';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { CgSpinner } from 'react-icons/cg';

import { firebaseAuth } from 'lib/firebase/firebase.client';
import { useAuthStore } from 'modules/auth/store/authStore';
import { authStoreActions } from 'modules/auth/store/authStore/actions';
import { isUserAnonymous } from 'modules/auth/store/authStore/utils/isUserAnonymous';
import { BaseProps } from 'modules/auth/utils/types';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { getIdToken, getUser, handleAuth } from 'utils/extension';

type OnAuthSuccessCallback = (user: User, isAnonymous: boolean) => void;
type OnAuthFailureCallback = (error: unknown) => void;

/**
 * @deprecated Use withServerAuth from @modules/auth/hocs/pages/withServerAuth instead.
 * withServerAuth provides better security by handling authentication on the server side
 * and supports both server-side and client-side authentication flows.
 *
 * Example usage:
 * ```tsx
 * const { page: MyPage } = withServerAuth(MyComponent);
 * export default MyPage;
 * ```
 */
const withAuth = <Props extends BaseProps = BaseProps>(Page: React.ComponentType<Props>) => {
  const AuthWithLoadedAuthStore = (props: Props) => {
    const router = useRouter();
    const user = useAuthStore(state => state.user);

    const onFailure = useCallback(() => {
      window.location.href = `/login?returnTo=${encodeURIComponent(window.location.href)}`;
    }, []);

    const onSuccess = useCallback(() => {}, []);

    // ESLint: React Hook "useCustomTokenAuth" and "useSessionAuth" is called conditionally
    /* eslint-disable react-hooks/rules-of-hooks */
    if (router.query.ctna) {
      useCustomTokenAuth(router.query.ctna as string, onSuccess, onFailure);
    } else {
      useSessionAuth(onSuccess, onFailure);
    }
    /* eslint-enable react-hooks/rules-of-hooks */

    return user ? (
      <Page {...props} />
    ) : (
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <CgSpinner size={30} className="animate-spin" />
      </div>
    );
  };

  if (!('waitForInitialHydration' in useAuthStore)) {
    return AuthWithLoadedAuthStore;
  }

  const usePersistedAuthStore = useAuthStore;

  const Auth = (props: Props) => {
    const [isAuthStoreHydrated, setIsAuthStoreHydrated] = useState(false);

    useEffect(() => {
      profilingStoreActions
        .measureAction(() => usePersistedAuthStore.waitForInitialHydration(), MeasurementKey.zustandIdbHydration, {
          storeName: 'authStore'
        })
        .then(() => setIsAuthStoreHydrated(true));
    }, []);

    if (!isAuthStoreHydrated) return;
    return <AuthWithLoadedAuthStore {...props} />;
  };

  return Auth;
};

export const handleOnUserSignedIn = (user: User, onSuccess: OnAuthSuccessCallback) => {
  authStoreActions.setUser(user);
  // update extension
  user.getIdToken().then(token => handleAuth('idtoken', token));
  onSuccess(user, isUserAnonymous(user));
};

export const handleSessionAuth = async ({
  onSuccess,
  onFailure,
  verifiedCookie
}: {
  onSuccess: OnAuthSuccessCallback;
  onFailure: OnAuthFailureCallback;
  verifiedCookie: DecodedIdToken | null;
}) => {
  authWithIdToken(verifiedCookie)
    .then(async () => {
      const token = await getCustomToken();
      const result = await signInWithCustomToken(firebaseAuth, token);
      const user = result.user;

      if (!user) {
        throw new Error('No users was returned');
      }

      handleOnUserSignedIn(user, onSuccess);
    })
    .catch(e => {
      onFailure(e);
    });
};

/**
 * @deprecated Use withServerAuth from @modules/auth/hocs/pages/withServerAuth with
 * shouldDoClientSideAuthWithChromeExtension: true instead.
 *
 * The withServerAuth approach is preferred because it eliminates the wasted time spent
 * verifying cookies by calling async fetch to the server. With withServerAuth, this verification
 * can be done directly on the server side during initial page load, resulting in better
 * performance and user experience.
 *
 * Example:
 * ```ts
 * const { page } = withServerAuth(YourPage, {
 *   shouldDoClientSideAuthWithChromeExtension: true
 * });
 * ```
 */
export const useSessionAuth = (onSuccess: OnAuthSuccessCallback, onFailure: OnAuthFailureCallback) => {
  useEffect(() => {
    handleSessionAuth({ onSuccess, onFailure, verifiedCookie: null });
  }, [onFailure, onSuccess]);
};

export const handleCustomTokenAuth = async ({
  customToken,
  onSuccess,
  onFailure
}: {
  customToken: string;
  onSuccess: OnAuthSuccessCallback;
  onFailure: OnAuthFailureCallback;
}) => {
  signInWithCustomToken(firebaseAuth, customToken)
    .then(({ user }) => user && handleOnUserSignedIn(user, onSuccess))
    .catch(e => onFailure(e));
};

/**
 * @deprecated Use withServerAuth from @modules/auth/hocs/pages/withServerAuth instead.
 *
 * This hook has issues with exhaustive dependencies and may cause unexpected behavior.
 * The withServerAuth approach is preferred for better maintainability and reliability.
 *
 * Please consult with `albertusdev` if you need to use this hook in newer/modern code.
 *
 * Example:
 * ```ts
 * const { page } = withServerAuth(YourPage, {
 *   shouldDoClientSideAuthWithChromeExtension: true
 * });
 * ```
 */
export const useCustomTokenAuth = (customToken: string, onSuccess: OnAuthSuccessCallback, onFailure: OnAuthFailureCallback) => {
  useEffect(() => {
    handleCustomTokenAuth({ customToken, onSuccess, onFailure });
    // ESLint: React Hook useEffect has missing dependencies: 'customToken', 'dispatch', 'onFailure', and 'onSuccess'. Either include them or remove the dependency array. If 'onSuccess' changes too often, find the parent component that defines it and wrap that definition in useCallback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const getCustomToken = () =>
  fetch('/api/auth/authenticate', { method: 'GET', cache: 'no-cache' })
    .then(response => response.json())
    .then(data => data?.token);

export const authWithIdToken = async (verifiedCookie: DecodedIdToken | null = null) => {
  verifiedCookie ??= await fetch('/api/auth/check-status', { method: 'GET' })
    .then(response => response.json())
    .catch(e => console.info(e));

  // fallback to extension to acquire token
  if (!verifiedCookie) {
    const idTokenPromise = getIdToken();
    const user = await getUser();

    if (user && !isUserAnonymous(user)) {
      const idToken = await idTokenPromise;
      await fetch('/api/auth/authenticate', { method: 'GET', cache: 'no-cache', headers: { Authorization: `Bearer ${idToken}` } });

      const token = await getCustomToken();

      if (token) {
        const user = await signOut(firebaseAuth)
          .then(() => signInWithCustomToken(firebaseAuth, token))
          .catch(e => console.info(e));

        return user;
      }
    } else {
      throw new Error('No user authenticated');
    }
  } else
    return {
      displayName: verifiedCookie.name,
      uid: verifiedCookie.uid,
      isAnonymous: false,
      email: verifiedCookie.email,
      email_verified: verifiedCookie.email_verified
    };
};

export default withAuth;
