import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { useListeningSettingsStore } from 'modules/listening/features/settings/settingsStore';
import { cn } from 'utils/cn';

import loaderDarkAnimation from './LoaderDark.json';
import loaderLightAnimation from './LoaderLight.json';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

let hideTimeoutId: NodeJS.Timeout;

export const PageLoader: React.FC<{ isLoading: boolean; className?: string }> = ({ isLoading, className }) => {
  const { darkMode } = useListeningSettingsStore();
  const [isVisible, setIsVisible] = useState(isLoading);

  useEffect(() => {
    if (!isLoading) {
      hideTimeoutId = setTimeout(() => setIsVisible(false), 300);
    } else {
      clearTimeout(hideTimeoutId);
      setIsVisible(true);
    }

    return () => clearTimeout(hideTimeoutId);
  }, [isLoading, setIsVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cn('fixed w-screen h-screen opacity-0 z-2000', isLoading && 'opacity-100', className)}>
      <Lottie
        className="w-[84px] h-[84px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        animationData={darkMode ? loaderDarkAnimation : loaderLightAnimation}
        loop={true}
      />
    </div>
  );
};
